<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")' href="#" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "github")' href="#" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' href="mailto:contact@devconseils.cloud" target='_blank'>contact&#64;devconseils.cloud </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_andresjose", "footer", "click")' href="#" target="_blank" rel="nofollow noopener noreferrer">
            <div>Didy M.</div>
        </a>
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_brittany", "footer", "click")' href="#" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Dev&Call Conseils Tech.</div>
               
        </a>
        <div class="mt-2"><a href="https://devconseils.cloud/app/" target="_blank"> Gestion App</a></div>
    </div>
    <div class="whatsapp-button">
        <a href="https://wa.me/212657558323?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services" target="_blank">
          <img src="assets/icons/whatsup.png" alt="WhatsApp">
        {{ "Contact.Whatsup" | translate}}
        </a>
      </div>
      
</footer>