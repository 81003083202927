<section class="section" id="contact">
  <div class="container contact">
    <div class="section-box ">
      <div class="mb-4 " data-aos="fade-up" data-aos-duration="1000">
        <h3 class="contact-pre-title big-subtitle">
          05. {{ "Contact.Pretitle" | translate }}
        </h3>
        <h4 class="e-font contact-title big-title">
          {{ "Contact.Title" | translate }}
        </h4>
      </div>

<div class="inline-group">
  <div class="elt1">
    
    <p data-aos="fade-up" data-aos-duration="1000">
      {{ "Contact.Content" | translate }}
    </p>
  </div>
  <div class="elt2">
    <div class="email-container">
   
      <form (submit)="sendEmail($event)">
        <div>
          <label for="name"> {{ "Contact.Nom" | translate }}</label>
          <input type="text" id="name" name="from_name" required>
        </div>
        <div>
          <label for="email"> {{ "Contact.Email" | translate }}</label>
          <input type="email" id="email" name="reply_to" required>
        </div>
        <div>
          <label for="forfait">{{ "Contact.Forfait" | translate }}</label>
          <select id="forfait" name="forfait">
            <option value="crm_unique">Freelance Unique</option>
            <option value="crm_basic">Freelance Basique</option>
            <option value="crm_Standard">Call center Standard</option>
            <option value="installation_config_basic">Installation & config Vicidial Basique</option>
            <option value="dev_informatique">Dev. Informatique</option>
          </select>
        </div>
        <div>
          <label for="message"> {{ "Contact.Message" | translate }}</label>
          <textarea id="message" name="message" required></textarea>
        </div>
        <button type="submit"> {{ "Contact.Btn" | translate }}</button>
      </form>
    </div>
    
  </div>
</div>

      <!--<div class="mb-4 " data-aos="fade-up" data-aos-duration="1000">
        <h3 class="contact-pre-title big-subtitle">
          05. {{ "Contact.Pretitle" | translate }}
        </h3>
        <h4 class="e-font contact-title big-title">
          {{ "Contact.Title" | translate }}
        </h4>
      </div>
      <p data-aos="fade-up" data-aos-duration="1000">
        {{ "Contact.Content" | translate }}
      </p>
     
<div class="form-group">

  <div class="email-container">
   
    <form (submit)="sendEmail($event)">
      <div>
        <label for="name"> {{ "Contact.Nom" | translate }}</label>
        <input type="text" id="name" name="from_name" required>
      </div>
      <div>
        <label for="email"> {{ "Contact.Email" | translate }}</label>
        <input type="email" id="email" name="reply_to" required>
      </div>
      <div>
        <label for="forfait">{{ "Contact.Forfait" | translate }}</label>
        <select id="forfait" name="forfait">
          <option value="crm_basic">CRM Basique</option>
          <option value="crm_Standard">CRM Standard</option>
          <option value="crm_advance">CRM Avancé</option>
          <option value="installation_config_basic">Installation & config Vicidial ou Goautodial 4 Basique</option>
          <option value="installation_config_advanced">Installation & config Vicidial ou Goautodial 4 Avancé</option>
          <option value="dev_informatique">Dev. Informatique</option>
        </select>
      </div>
      <div>
        <label for="message"> {{ "Contact.Message" | translate }}</label>
        <textarea id="message" name="message" required></textarea>
      </div>
      <button type="submit"> {{ "Contact.Btn" | translate }}</button>
    </form>
  </div>
  


</div>
      <div class="mt-5">
        <a
          (click)="
            analyticsService.sendAnalyticEvent(
              'click_send_mail',
              'contact',
              'email'
            )
          "
          href="mailto:dicomamtz@gmail.com"
          target="_blank"
          class="main-btn"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {{ "Contact.Btn" | translate }}
        </a>
      </div>-->
    </div>



  
  </div>




</section>
