import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor(
    public analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
  }
  public sendEmail(e: Event) {
    e.preventDefault();
    
    emailjs.sendForm('service_cinkw3j', 'template_1sy1aiv', e.target as HTMLFormElement, 'hv93uLS0AsHgrfZhj')
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
        alert('Email envoyé avec succès !');
      }, (error) => {
        console.log(error.text);
        alert('Erreur lors de l\'envoi de l\'email.');
      });
  }
}
