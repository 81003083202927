import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

  visibleCRM: boolean = true;
  visibleInstall: boolean = false;
  visibleDev: boolean = false;
  responsiveMenuVisible: Boolean = false;
  constructor(private router: Router

  ) { }

  ngOnInit(): void {
  }
  Affiche(title: any, nbr: any) {
    console.log(nbr);
    if (nbr == 0) {
      this.visibleCRM = true;
      this.visibleInstall = false;
      this.visibleDev = false;
    } else if (nbr > 1) {
      this.visibleCRM = false;
      this.visibleInstall = false;
      this.visibleDev = true;
    } else {
      this.visibleCRM = false;
      this.visibleInstall = true;
      this.visibleDev = false;
    }
  }

  
  scroll(el) {
    if(document.getElementById(el)) {
      document.getElementById(el).scrollIntoView({behavior: 'smooth'});
    } else{
      this.router.navigate(['/home']).then(()=> document.getElementById(el).scrollIntoView({behavior: 'smooth'}) );
    }
    this.responsiveMenuVisible=false;
  }
}
