<section class="section" id="price">
    <div class="container price">
      <div class="section-box">
        <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
          <h3 class="e-font section-title">
            <span class="code-font n-section-title">05.</span>
            {{ "Price.Title" | translate }}
          </h3>
        </div>
        
<div class="container_price" data-aos="fade-up" data-aos-duration="1000">

    <section class="pricing-table">
      
        <ul class="nav nav-pills" id="myTab" role="tablist" >
            <li class="nav-item" role="presentation" *ngFor="let price of 'Price.Categories' | translate; let i = index">
                <button class="nav-link active" id="crm-tab" data-bs-toggle="pill" data-bs-target="#crm" type="button" role="tab" aria-controls="crm" aria-selected="true" (click)="Affiche(price.Title,i)" >  {{ price.Title }}</button>
            </li>
        </ul>

        <div class="tab-content" >
            <!-- CRM d'appels -->
            <div  *ngIf="visibleCRM" role="tabpanel" aria-labelledby="crm-tab">
                <div class="pricing-cards">
                    <!-- Basic -->
                    <div class="pricing-card option.Class" *ngFor="let option of 'Price.Options' | translate; let i = index">
                        <h2>{{option.Title}} </h2>
                        <ul>
                            <li>{{option.Cible}}</li>
                            <li>{{option.Prix}}</li>
                            <li>{{option.TypeOp}}</li>
                            <li>{{option.NbrUser}}</li>
                            <li>{{option.Licence}}</li>
                            <li>{{option.Numero}}</li>
                           
                        </ul>
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
                    </div>
                   
                   
                </div>
            </div>

            <!-- Installation et configuration -->
            <div  *ngIf="visibleInstall" aria-labelledby="install-tab">
                <div class="pricing-cards">
                    <!-- Basic -->
                    <div class="pricing-card">
                        <h2>Basic</h2>
                        <ul>
                            <li>Freelancers : 299 €</li>
                            <li>Installation unique</li>
                        </ul>
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
                    </div>
                    <!-- Standard & Advanced -->
                    <div class="pricing-card standard">
                        <h2>Standard & Advanced</h2>
                        <ul>
                            <li>Call centers : À partir de 500 €</li>
                        </ul> 
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
              
                    </div>
                     </div>
            </div>

            <!-- Développement d'applications -->
            <div  *ngIf="visibleDev" role="tabpanel" aria-labelledby="dev-tab">
                <div class="pricing-cards">
                    <div class="pricing-card">
                        <h2>Sur devis personnalisé</h2>
                        <ul>
                            <li>Tarification selon la complexité du projet</li>
                        </ul>
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
</div>
</div>
</section>