<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Angular </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">RxJS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">HTML &amp; (S)CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Laravel </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Express </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Cypress (TDD) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">CI / CD </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">WordPress</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0 text-center" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-img-containers">
                      



                        <div class="flip-box" (click)="flip()">
                            <div class="flip-box-inner" [class.flipped]="isFlipped">
                              <!-- Front Side -->
                              <div class="flip-box-front">
                               
                                <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")' class="rounded"  src="assets/images/goautodial/agent2.png" alt="goautodial">
                       
                              </div>
                              <!-- Back Side -->
                              <div class="flip-box-back">
                               
                                <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")' class="rounded"  src="assets/images/goautodial/vicidial1.png" alt="vicidial">
                  
                              </div>
                            </div>
                          </div>
                          











                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
