<section class="section" id="price">
    <div class="container price">
      <div class="section-box">
        <div class="about-title mb-5" data-aos="fade-up" data-aos-duration="1000">
          <h3 class="e-font section-title">
            <span class="code-font n-section-title">05.</span>
            {{ "Price.Title" | translate }}
          </h3>
        </div>
        
<div class="container_price" data-aos="fade-up" data-aos-duration="1000">

    <section class="pricing-table">
      
        <!--<ul class="nav nav-pills" id="myTab" role="tablist" >
            <li class="nav-item" role="presentation" *ngFor="let price of 'Price.Categories' | translate; let i = index">
                <button 
                [class]="nav-link active" 
                id="crm-tab" 
                data-bs-toggle="pill" 
                data-bs-target="#crm" 
                type="button" 
                role="tab" 
                aria-controls="crm" 
                aria-selected="true" 
                (click)="Affiche(price.Title,i)" >  {{ price.Title }}</button>
            </li>
        </ul>-->



        <ul class="nav nav-pills" id="myTab" role="tablist">
            <li class="nav-item" role="presentation" *ngFor="let price of 'Price.Categories' | translate; let i = index">
              <button 
                class="nav-link" 
                [class.active]="i === 0" 
                [id]="'tab-' + i" 
                data-bs-toggle="pill" 
                [attr.data-bs-target]="'#panel-' + i" 
                type="button" 
                role="tab" 
                [attr.aria-controls]="'panel-' + i" 
                [attr.aria-selected]="i === 0 ? 'true' : 'false'" 
                (click)="Affiche(price.Title, i)">
                {{ price.Title }}
              </button>
            </li>
          </ul>
          


        <div class="tab-content" >
            <!-- CRM d'appels -->
            <div  *ngIf="visibleCRM" role="tabpanel" aria-labelledby="crm-tab">
                <div class="pricing-cards">
                    <!-- Basic -->
                    <div class="pricing-card option.Class" *ngFor="let option of 'Price.Options' | translate; let i = index">
                        <h2>{{option.Title}} </h2>
                        <ul>
                            <li>{{option.Cible}}</li>
                            <li>{{option.Prix}}</li>
                            <li>{{option.TypeOp}}</li>
                            <li>{{option.NbrUser}}</li>
                            <li>{{option.Licence}}</li>
                            <li>{{option.Numero}}</li>
                           
                        </ul>
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
                    </div>
                   
                   
                </div>
            </div>

            <!-- Installation et configuration -->
            <div  *ngIf="visibleInstall" aria-labelledby="install-tab">
                <div class="pricing-cards" *ngFor="let option of 'Price.Options2' | translate; let i=index">
                   
                    <div class="pricing-card">
                        <h2>{{option.Title}}</h2>
                        <ul>
                            <li>{{option.Prix}}</li>
                            <li>{{option.Caracteristique}}</li>
                            <li>{{option.Outil}}</li>
                            <li>{{option.Formation}}</li>
                        </ul>
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
                    </div>
                </div>
            </div>

            <!-- Développement d'applications -->
            <div  *ngIf="visibleDev" role="tabpanel" aria-labelledby="dev-tab">
                <div class="pricing-cards" *ngFor="let option of 'Price.Options3' | translate; let i=index">
                    <div class="pricing-card">
                        <h2>{{option.Title}}</h2>
                        <ul>
                            <li>{{option.Prix}}</li>
                            <li>{{option.Caracteristique}}</li>
                            <li>{{option.Outil}}</li>
                            <li>{{option.Formation}}</li>
                        </ul>
                        <button (click)='scroll("contact")'>{{ "Price.Btn" | translate}}</button>
                    </div>   
                </div>
            </div>
           
        </div>
    </section>
</div>
</div>
</div>
</section>